@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* GENERAL NAVBAR STYLING */
.navbar-items {
  background: linear-gradient(90deg, #37474f 0%, #78909c 100%);
  min-height: 3.5em;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.0rem;
  grid-gap: 1em;
  gap: 1em;
  flex-wrap: wrap;
}

.navbar-items-tag {
  color : white;
  padding-left: 1em;
  padding-right: 1em;
  text-decoration: none;
}

.navbar-logo {
  color: #eceff1;
  justify-self: left;
  margin-left: 1.5vw;
  cursor: pointer;
  /* width: 40vw; default none */
}

.navbar-logo-viewer {
  color: #eceff1;
  justify-self: left;
  margin-left: 1.5vw;
  cursor: pointer;
  width: 20vw; /* default none */
}

.menu-icon {
  display: none;
  color: white;
  font-size: 1.4rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 40vw; /* default 60 */
  justify-content: end;
  margin-right: 2rem;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-link:hover {
  background-color: #546e7a;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.navbar-upload {
  margin-left: -20px;
}

.nav-link-mobile {
  display: none;
}

.widget
{
  max-width: 50em;
  padding: 2em;
  box-shadow: 2px 2px 10px gray;
  border-radius: 1em;
  text-align: left;

  margin-right: 1em;
  margin-bottom: 1em;
  margin-left: 1em;
}

.pub
{
  grid-gap: 0.5em;
  gap: 0.5em;
  display: flex;
  flex-direction: column;
}

.pub span
{
  cursor: pointer;
}

.pub span:hover
{
  text-decoration: underline;
}

.MuiGridListTile-tile
{
  border-radius: 1em;
  box-shadow: 5px 5px 10px #8080806b;
  cursor : "pointer",
}

.viewer-control
{
  pointer-events: none;
}

.viewer-control *
{
  pointer-events: auto;
}

@media screen and (max-width: 960px) {
  .navbar-items {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    /* top: 80px; */
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #78909c;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-link {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-link:hover {
    background-color: #546e7a;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-link-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 0.5rem auto;
    border-radius: 4px;
    width: 80%;
    background: #eceff1;
    text-decoration: none;
    color: #263238;
    font-size: 1.4rem;
  }

  .nav-link-mobile:hover {
    background: rgba(255, 255, 255, 0.6);
    color: #263238;
    transition: 250ms;
  }

  .navbar-upload {
    display: none;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

